import { combineReducers } from '@reduxjs/toolkit';
import fellowsReducer from './reducers/fellowsReducer';
import courseReducer from './reducers/courseReducer';
import paymentReducer from './reducers/paymentReducer';
import registrationReducer from './reducers/registrationReducer';
import catalogReducer from './reducers/catalogReducer';
import meetTheFoundryReducer from './reducers/meetTheFoundryReducer';
import learningResourcesReducer from './reducers/learningResourcesReducer';

const rootReducer = combineReducers({
  fellowsReducer,
  courseReducer,
  paymentReducer,
  registrationReducer,
  catalogReducer,
  meetTheFoundryReducer,
  learningResourcesReducer,
});

export default rootReducer;
