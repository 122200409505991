import { createAction, createReducer } from '@reduxjs/toolkit';

// Initial state
const initialState = {
  allCourses: [],
  searchCourses: [],
  filtersAreDisabled: false,
  activeFormats: {},
  activeTopics: {},
  activePayment: "All",
  activeAvailability: "All",
  activeTimeframe: "All",
  activeSortValue: "By Date",
};

// Actions
export const setAllCourses = createAction('SET_ALL_COURSES');
export const setSearchCourses = createAction('SET_SEARCH_COURSES');
export const setSearchCriteria = createAction('SET_SEARCH_CRITERIA');
export const setFiltersAreDisabled = createAction('SET_FILTERS_ARE_DISABLED');
export const setActiveFormats = createAction('SET_ACTIVE_FORMATS');
export const setActiveTopics = createAction('SET_ACTIVE_TOPICS');
export const setActivePaymentType = createAction('SET_ACTIVE_PAYMENT_TYPE');
export const setActiveAvailabilityType = createAction('SET_ACTIVE_AVAILABILITY_TYPE');
export const setActiveTimeframe = createAction('SET_ACTIVE_TIMEFRAME');
export const setActiveSortValue = createAction('SET_ACTIVE_SORT_VALUE');

const filterByAvailability = (availability, dataToFilter) => {
  const availabilityMatches = dataToFilter.filter(course => {
    if (availability.toLowerCase().trim() === "all") return course;
    return availability.toLowerCase().trim() === "on demand"
      ? course.courseAvailability.toLowerCase().trim() === "on demand"
      : course.courseAvailability.toLowerCase().trim() !== "on demand";
  });

  return availabilityMatches;
};

const filterByPayment = (payment, dataToFilter) => {
  const paymentMatches = dataToFilter.filter(course => {
    if (payment.toLowerCase().trim() === "all") return course;
    return payment.toLowerCase().trim() === "free"
      ? course.courseCost.toLowerCase().trim() === "free"
      : course.courseCost.toLowerCase().trim() !== "free";
  });

  return paymentMatches;
};

const filterByTopic = (topics, dataToFilter) => {
  const filteredCourses = dataToFilter.filter(course => {
    return Object.entries(topics).find(topic => {
      return course.theme
        ? course.theme.theme.toUpperCase().includes(topic[0].toUpperCase()) && topic[1]
        : course.courseType.toUpperCase().includes(topic[0].toUpperCase()) && topic[1];
      });
  });

  return filteredCourses;
};

const filterByTimeframe = (timeframe, dataToFilter) => {
  let filteredCourses,
    now = new Date();
  switch (timeframe.toLowerCase()) {
    case "upcoming":
      filteredCourses = dataToFilter.filter(
        course => new Date(course.startDate) > now
      );
      break;
    case "current":
      filteredCourses = dataToFilter.filter(
        course =>
          new Date(course.startDate) < now && new Date(course.endDate) > now
      );
      break;
    case "past":
      filteredCourses = dataToFilter.filter(
        course => new Date(course.endDate) < now
      );
      break;
    default:
      filteredCourses = dataToFilter;
      break;
  }

  return filteredCourses;
};

const filterByFormat = (formats, dataToFilter) => {
  const filteredCourses = dataToFilter.filter(course => {
    return Object.entries(formats).find(format => {
      return course.theme
        ? course.courseType.toUpperCase().includes(format[0].toUpperCase()) && format[1]
        : course.productType.toUpperCase().includes(format[0].toUpperCase()) && format[1];
    });
  });

  return filteredCourses;
};

const sortSearchData = (sort, dataToSort) => {
  let sortedCourses = [];
  if (sort.toUpperCase().includes("(A-Z)")) {
    sortedCourses = dataToSort.sort((a, b) => {
      const titleA = a.title ? a.title.toUpperCase() : a.name.toUpperCase(),
        titleB = b.title ? b.title.toUpperCase() : b.name.toUpperCase();
      if (titleA < titleB) {
        return -1;
      }
      if (titleA > titleB) {
        return 1;
      }
      return 0;
    });
  } else if (sort.toUpperCase().includes("(Z-A)")) {
    sortedCourses = dataToSort.sort((a, b) => {
      const titleA = a.title ? a.title.toUpperCase() : a.name.toUpperCase(),
        titleB = b.title ? b.title.toUpperCase() : b.name.toUpperCase();
      if (titleA < titleB) {
        return 1;
      }
      if (titleA > titleB) {
        return -1;
      }
      return 0;
    });
  } else if (sort.toUpperCase().includes("DATE")) {
    sortedCourses = dataToSort.sort((a, b) => {
      return new Date(a["courseStartDate"]) - new Date(b["courseStartDate"]);
    });
  } else if (sort.toUpperCase().includes("UPCOMING")) {
    const now = new Date();
    sortedCourses = dataToSort.sort(a => {
      if (a.courseStartDate) {
        const jsADate = new Date(a.courseStartDate);
        if (jsADate < now) {
          return 1;
        }
        if (jsADate > now) {
          return -1;
        }
        return 0;
      }
      return 0;
    });
  }

  return sortedCourses;
};

const compileSearchResults = (state) => {
  let searchData = state.allCourses;
  if (!state.filtersAreDisabled) {
    searchData = filterByAvailability(state.activeAvailability, searchData);
    searchData = filterByPayment(state.activePayment, searchData);
    searchData = filterByTopic(state.activeTopics, searchData);
    searchData = filterByTimeframe(state.activeTimeframe, searchData);
    searchData = filterByFormat(state.activeFormats, searchData);
  }
  searchData = sortSearchData(state.activeSortValue, searchData);
  return searchData;
};

// Reducer
const courseReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(setAllCourses, (state, action) => {
      state.allCourses = action.payload;
    })
    .addCase(setSearchCourses, (state) => {
      state.searchCourses = compileSearchResults(state);
    })
    .addCase(setSearchCriteria, (state, action) => {
      state.searchCourses = action.payload;
    })
    .addCase(setFiltersAreDisabled, (state, action) => {
      state.filtersAreDisabled = action.payload;
    })
    .addCase(setActiveFormats, (state, action) => {
      state.activeFormats = action.payload;
    })
    .addCase(setActiveTopics, (state, action) => {
      state.activeTopics = action.payload;
    })
    .addCase(setActivePaymentType, (state, action) => {
      state.activePayment = action.payload;
    })
    .addCase(setActiveAvailabilityType, (state, action) => {
      state.activeAvailability = action.payload;
    })
    .addCase(setActiveTimeframe, (state, action) => {
      state.activeTimeframe = action.payload;
    })
    .addCase(setActiveSortValue, (state, action) => {
      state.activeSortValue = action.payload;
    });
});

export default courseReducer;
