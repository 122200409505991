import { createAction, createReducer } from '@reduxjs/toolkit';

// Initial State
const initialState = {
  resources: [],
  searchTerm: "",
};

// Actions
export const setResources = createAction('SET_RESOURCES');
export const setSearchTerm = createAction('SET_SEARCH_TERM');

// Reducer
const learningResourcesReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(setResources, (state, action) => {
      state.resources = action.payload;
    })
    .addCase(setSearchTerm, (state, action) => {
      state.searchTerm = action.payload;
    });
});

export default learningResourcesReducer;
