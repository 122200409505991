import { createAction, createReducer } from '@reduxjs/toolkit';

// Initial State
const initialState = {
  checkedFellowshipRegions: [],
  checkedFellowshipYears: [],
  searchName: "",
  searchResults: [],
  fellowshipSearchResultsLimit: 12,
  footerHeight: 0,
  pendingCourseEnrollmentNotification: null,
};

// Actions
export const setCheckedFellowshipRegions = createAction('SET_CHECKED_FELLOWSHIP_REGIONS');
export const setCheckedFellowshipYears = createAction('SET_CHECKED_FELLOWSHIP_YEARS');
export const setFellowshipSearchName = createAction('SET_FELLOWSHIP_SEARCH_NAME');
export const setFellowsSearchResults = createAction('SET_FELLOWS_SEARCH_RESULTS');
export const setFellowsSearchResultsLimit = createAction('SET_FELLOWS_SEARCH_RESULTS_LIMIT');
export const setFooterHeight = createAction('SET_FOOTER_HEIGHT');
export const setPendingCourseEnrollmentNotification = createAction('SET_PENDING_COURSE_ENROLLMENT_NOTIFICATION');

// Reducer
const fellowsReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(setCheckedFellowshipRegions, (state, action) => {
      state.checkedFellowshipRegions = action.payload;
    })
    .addCase(setCheckedFellowshipYears, (state, action) => {
      state.checkedFellowshipYears = action.payload;
    })
    .addCase(setFellowshipSearchName, (state, action) => {
      state.searchName = action.payload;
    })
    .addCase(setFellowsSearchResults, (state, action) => {
      state.searchResults = action.payload;
    })
    .addCase(setFellowsSearchResultsLimit, (state, action) => {
      state.fellowshipSearchResultsLimit = action.payload;
    })
    .addCase(setFooterHeight, (state, action) => {
      state.footerHeight = action.payload;
    })
    .addCase(setPendingCourseEnrollmentNotification, (state, action) => {
      state.pendingCourseEnrollmentNotification = action.payload;
    });
});

export default fellowsReducer;
