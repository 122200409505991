import { createAction, createReducer } from '@reduxjs/toolkit';

// Initial State
const initialState = {
  activeFilters: [],
  searchTerm: "",
  selectedFoundryMember: {},
  foundryMemberModalState: false,
};

// Actions
export const setActiveFilters = createAction('SET_ACTIVE_FILTERS');
export const setSelectedFoundryMember = createAction('SET_SELECTED_FOUNDRY_MEMBER');
export const setFoundryMemberModalState = createAction('SET_FOUNDRY_MEMBER_MODAL_STATE');
export const setSearchTerm = createAction('SET_SEARCH_TERM');

// Reducer
const meetTheFoundryReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(setActiveFilters, (state, action) => {
      state.activeFilters = action.payload;
    })
    .addCase(setSelectedFoundryMember, (state, action) => {
      state.selectedFoundryMember = action.payload;
    })
    .addCase(setFoundryMemberModalState, (state, action) => {
      state.foundryMemberModalState = action.payload;
    })
    .addCase(setSearchTerm, (state, action) => {
      state.searchTerm = action.payload;
    });
});

export default meetTheFoundryReducer;
