/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// Pollyfill for Safari
// https://github.com/gatsbyjs/gatsby/issues/12074#issuecomment-467052571
export const onClientEntry = async () => {
 if (typeof IntersectionObserver === "undefined") {
   await import("intersection-observer");
 }
};

export { default as wrapRootElement } from "./src/state/implementation";
