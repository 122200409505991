import { createAction, createReducer } from '@reduxjs/toolkit';

// Initial State
const initialState = {
  name: "",
  billingCountry: null,
  province: null,
  address: "",
  city: "",
  zip: "",
  suggestedPrice: "",
};

// Actions
export const setName = createAction('SET_NAME');
export const setBillingCountry = createAction('SET_BILLING_COUNTRY');
export const setProvince = createAction('SET_PROVINCE');
export const setAddress = createAction('SET_ADDRESS');
export const setCity = createAction('SET_CITY');
export const setZip = createAction('SET_ZIP');
export const setSuggestedPrice = createAction('SET_SUGGESTED_PRICE');

// Reducer
const paymentReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(setName, (state, action) => {
      state.name = action.payload;
    })
    .addCase(setBillingCountry, (state, action) => {
      state.billingCountry = action.payload;
    })
    .addCase(setProvince, (state, action) => {
      state.province = action.payload;
    })
    .addCase(setAddress, (state, action) => {
      state.address = action.payload;
    })
    .addCase(setCity, (state, action) => {
      state.city = action.payload;
    })
    .addCase(setZip, (state, action) => {
      state.zip = action.payload;
    })
    .addCase(setSuggestedPrice, (state, action) => {
      state.suggestedPrice = action.payload;
    });
});

export default paymentReducer;
