import { createAction, createReducer } from '@reduxjs/toolkit';

// Initial State
const initialState = {
  firstName: "",
  lastName: "",
  email: "",
  mailingList: false,
};

// Actions
export const setFirstName = createAction('SET_FIRST_NAME');
export const setLastName = createAction('SET_LAST_NAME');
export const setEmail = createAction('SET_EMAIL');
export const setMailingList = createAction('SET_MAILING_LIST');

// Reducer
const registrationReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(setFirstName, (state, action) => {
      state.firstName = action.payload;
    })
    .addCase(setLastName, (state, action) => {
      state.lastName = action.payload;
    })
    .addCase(setEmail, (state, action) => {
      state.email = action.payload;
    })
    .addCase(setMailingList, (state, action) => {
      state.mailingList = action.payload;
    });
});

export default registrationReducer;
