import { createAction, createReducer } from '@reduxjs/toolkit';

// Actions
export const setGridCourses = createAction('SET_GRID_COURSES');
export const setLanguages = createAction('SET_LANGUAGES');
export const setThemes = createAction('SET_THEMES');
export const setSubjects = createAction('SET_SUBJECTS');
export const setFormats = createAction('SET_FORMATS');
export const setLengths = createAction('SET_LENGTHS');
export const setSearchTerm = createAction('SET_SEARCH_TERM');

// Initial state
const initialState = {
  gridCourses: [],
  languages: [],
  themes: [],
  subjects: [],
  formats: [],
  lengths: [],
  searchTerm: "",
};

// Reducer
const catalogReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(setGridCourses, (state, action) => {
      state.gridCourses = action.payload;
    })
    .addCase(setLanguages, (state, action) => {
      state.languages = action.payload;
    })
    .addCase(setThemes, (state, action) => {
      state.themes = action.payload;
    })
    .addCase(setSubjects, (state, action) => {
      state.subjects = action.payload;
    })
    .addCase(setFormats, (state, action) => {
      state.formats = action.payload;
    })
    .addCase(setLengths, (state, action) => {
      state.lengths = action.payload;
    })
    .addCase(setSearchTerm, (state, action) => {
      state.searchTerm = action.payload;
    });
});

export default catalogReducer;
